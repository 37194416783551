<template>
    <div>
        <v-container fluid class="px-0">
            <v-card outlined elevation="3">
                <v-card-text>
                    <v-data-table
                        class="black--text"
                        :headers="headers"
                        :search="search"
                        :items="dataInbound"
                        :loading="loading"
                        :items-per-page="itemsPerPage"
                        :page.sync="page"
                        hide-default-footer
                        dense
                    >
                        <template v-slot:top>
                            <v-toolbar flat dense class="mb-5">
                                <v-row dense>
                                    <v-col class="pa-0 mr-2 d-none">
                                        <v-select
                                            v-model="expiredDate"
                                            label="Expired Date"
                                            dense
                                            outlined
                                            hide-details
                                            :items="listExpiredDate"
                                            item-value="expired_date"
                                            v-on:change="applyFilter"
                                        >
                                            <template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                <span
                                                    v-if="
                                                        data.item
                                                            .expired_date == ''
                                                    "
                                                    >all</span
                                                >
                                                <span v-else>{{
                                                    data.item.expired_date.substring(
                                                        0,
                                                        10
                                                    )
                                                }}</span>
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                <span
                                                    v-if="
                                                        data.item
                                                            .expired_date == ''
                                                    "
                                                    >all</span
                                                >
                                                <span v-else>{{
                                                    data.item.expired_date.substring(
                                                        0,
                                                        10
                                                    )
                                                }}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col class="pa-0 mr-2 d-none">
                                        <v-select
                                            v-model="manufacturedDate"
                                            label="Manufactured Date"
                                            dense
                                            outlined
                                            hide-details
                                            :items="listManufacturedDate"
                                            item-value="manufacturing_date"
                                            v-on:change="applyFilter"
                                        >
                                            <template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                <span
                                                    v-if="
                                                        data.item
                                                            .manufacturing_date ==
                                                            ''
                                                    "
                                                    >all</span
                                                >
                                                <span v-else>{{
                                                    data.item.manufacturing_date.substring(
                                                        0,
                                                        10
                                                    )
                                                }}</span>
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                <span
                                                    v-if="
                                                        data.item
                                                            .manufacturing_date ==
                                                            ''
                                                    "
                                                    >all</span
                                                >
                                                <span v-else>{{
                                                    data.item.manufacturing_date.substring(
                                                        0,
                                                        10
                                                    )
                                                }}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col class="pa-0 mr-2 d-none">
                                        <v-select
                                            v-model="tujuanGudang"
                                            label="Gudang Tujuan"
                                            dense
                                            outlined
                                            hide-details
                                            :items="dataGudangTujuan"
                                            item-value="id"
                                            item-text="name"
                                            v-on:change="applyFilter"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="d-flex mb-5" cols="2">
                                        <v-select
                                            v-model="asalGudang"
                                            label="Gudang Asal"
                                            dense
                                            outlined
                                            hide-details
                                            :items="dataGudangAsal"
                                            item-value="id"
                                            item-text="name"
                                            v-on:change="applyFilter"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="pa-0 mr-2 d-none">
                                        <v-select
                                            v-model="detailTransaksi"
                                            label="Detail Transaksi"
                                            dense
                                            outlined
                                            hide-details
                                            :items="listDetail"
                                            item-value="id"
                                            item-text="name"
                                            v-on:change="applyFilter"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="pa-0 d-none">
                                        <download-csv
                                            :data="dataJsonToCsv"
                                            name="Laporan Inbound.csv"
                                        >
                                            <v-btn
                                                color="#61B15A"
                                                dark
                                                class="mb-2 text-subtitle-1"
                                                style="text-transform: unset !important"
                                            >
                                                <v-icon left
                                                    >mdi-file-download</v-icon
                                                >
                                                Unduh Data
                                            </v-btn>
                                        </download-csv>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                            <v-toolbar flat dense class="mb-5">
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    autocomple="off"
                                    placeholder="Search..."
                                ></v-text-field>
                            </v-toolbar>
                            <div
                                class="d-flex mb-5 px-4 justify-space-between align-center"
                            >
                                <v-row>
                                    <v-col cols="2">
                                        <v-select
                                            v-model="itemsPerPage"
                                            :items="showPerPage"
                                            outlined
                                            dense
                                            label="items per page"
                                            v-on:change="changeItemPerPage"
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col align="right">
                                        <span class="mr-15 text-caption"
                                            >Page: {{ page }}</span
                                        >
                                        <v-btn
                                            fab
                                            x-small
                                            depressed
                                            color="primary"
                                            class="mx-3"
                                            @click="prevPage"
                                        >
                                            <v-icon dark>
                                                mdi-chevron-left
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            fab
                                            x-small
                                            depressed
                                            color="primary"
                                            class="mx-3"
                                            @click="nextPage"
                                        >
                                            <v-icon dark>
                                                mdi-chevron-right
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            {{ item.created_at | moment("YYYY/MM/DD") }}
                        </template>

                        <template v-slot:item.manufacturing_date="{ item }">
                            {{ item.manufacturing_date | moment("YYYY/MM/DD") }}
                        </template>

                        <template v-slot:item.expired_date="{ item }">
                            {{ item.expired_date | moment("YYYY/MM/DD") }}
                        </template>

                        <template v-slot:item.mc="{ item }">
                            {{ getValueOfQty(item.mc, "MC", item) }}
                        </template>
                        <template v-slot:item.ic="{ item }">
                            {{ getValueOfQty(item.ic, "IC", item) }}
                        </template>
                        <template v-slot:item.qr="{ item }">
                            {{ getValueOfQty(item.qr, "BTL", item) }}
                        </template>

                        <template v-slot:item.totBtl="{ item }">
                            {{ getTotalAll(item.mc, item.ic, item.qr) }}
                        </template>
                        <template v-slot:item.actions="{ item, index }">
                            <template>
                                <v-btn
                                    color="#FFA900"
                                    small
                                    class="text-caption white--text mr-2 my-1"
                                    style="text-transform: unset !important"
                                    @click.prevent="edit(index)"
                                >
                                    TP
                                </v-btn>
                            </template>
                        </template>
                    </v-data-table>
                    <div
                        class="d-flex mt-5 px-4 justify-space-between align-center"
                    >
                        <v-row>
                            <v-col cols="2">
                                <v-select
                                    v-model="itemsPerPage"
                                    :items="showPerPage"
                                    outlined
                                    dense
                                    label="items per page"
                                    v-on:change="changeItemPerPage"
                                >
                                </v-select>
                            </v-col>
                            <v-col align="right">
                                <span class="mr-15 text-caption"
                                    >Page: {{ page }}</span
                                >
                                <v-btn
                                    fab
                                    x-small
                                    depressed
                                    color="primary"
                                    class="mx-3"
                                    @click="prevPage"
                                >
                                    <v-icon dark> mdi-chevron-left </v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    x-small
                                    depressed
                                    color="primary"
                                    class="mx-3"
                                    @click="nextPage"
                                >
                                    <v-icon dark> mdi-chevron-right </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>

        <edit-data
            :editDataDialog="editDataDialog"
            :olahData="olahData"
            :dataGudang="dataGudang"
            :listGudang="listGudang"
            @closeEditDataDialog="closeEditDataDialog"
            @reloadDataEdit="reloadDataEdit"
        />

        <v-dialog v-model="progress" width="500">
            <v-card>
                <v-card-text class="pt-5">
                    <v-progress-linear
                        indeterminate
                        color="#FFA900"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="popDialog" width="500">
            <v-card color="white" rounded>
                <v-card-title class="justify-center text-h6 black--text">
                    {{ popTitle }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light-green darken-1"
                        class="white--text"
                        @click="closePopDialog"
                        >Tutup</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import EditData from "./editData.vue";

export default {
    components: { EditData },
    data: () => ({
        search: "",
        loading: false,
        manufacturedDate: "",
        listManufacturedDate: [],
        expiredDate: "",
        listExpiredDate: [],
        listDetail: [
            { id: "", name: "semua transaksi" },
            { id: "HASIL_PRODUKSI", name: "Hasil Produksi" },
            { id: "ANTAR_GUDANG", name: "Antar Gudang" },
            { id: "RETUR", name: "Retur" },
        ],
        headers: [
            // {
            //   text: "Detail Transaksi",
            //   class: "text-subtitle-2 font-weight-bold black--text",
            //   value: "name",
            // },
            {
                text: "Asal Gudang",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "gudang_penerima_name",
            },
            {
                text: "Surat Jalan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "nomer_seri",
            },
            {
                text: "Nama Customer",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "customer_name",
            },
            // {
            //   text: "Loading Date",
            //   class: "text-subtitle-2 font-weight-bold black--text",
            //   value: "created_at",
            // },
            // {
            //   text: "Batch",
            //   class: "text-subtitle-2 font-weight-bold black--text",
            //   value: "batch_no",
            // },
            {
                text: "MC",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "mc",
            },
            {
                text: "IC",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "ic",
            },
            {
                text: "Botol/Slv/Box",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "qr",
            },
            {
                text: "Total Botol/Slv/Box",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "totBtl",
            },
            {
                text: "Action",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "actions",
                // width: "300px",
            },
            // {
            //   text: "Manufacture Date",
            //   class: "text-subtitle-2 font-weight-bold black--text",
            //   value: "manufacturing_date",
            // },
            // {
            //   text: "Expired Date",
            //   class: "text-subtitle-2 font-weight-bold black--text",
            //   value: "expired_date",
            // },
            // {
            //   text: "Change By",
            //   class: "text-subtitle-2 font-weight-bold black--text",
            //   value: "scanner_name",
            // },
        ],
        dataGudang: [],
        listGudang: [],
        dataInbound: [],
        dataGudangAsal: [],
        asalGudang: "",
        dataGudangTujuan: [],
        tujuanGudang: "",
        detailTransaksi: "",
        expired_from: "",
        expired_to: "",
        manufacture_from: "",
        manufacture_to: "",
        dataJsonToCsv: [],
        page: 1,
        itemsPerPage: 10,
        showPerPage: [5, 10, 20, 50, 100, 250, 500],
        editDataDialog: false,
        progress: false,
        popDialog: false,
        popTitle: "",
        olahData: {},
        summList: [],
        sjid: "",
    }),

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),

        applyFilter() {
            this.loading = true;

            axios
                .get(
                    "v1/admin/surat_jalan/tp_retur?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page +
                        "&gudang_penerima_id=" +
                        this.asalGudang
                )
                .then((response) => {
                    this.loading = false;
                    this.dataInbound = response.data.data;
                    this.getGudang();
                    // this.unduhData();
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.applyFilter();
                        }
                        console.clear();
                    }
                });
        },
        getGudang() {
            this.listGudang = [];
            this.user.listGudang.forEach((v) => {
                axios.get("v1/user/gudang?id=" + v).then((response) => {
                    if (response.data.data.is_activated === 1) {
                        this.listGudang.push(response.data.data);
                    }
                });
            });
            axios.get("v1/user/gudang/list?limit=100").then((response) => {
                response.data.data.forEach((v) => {
                    if (
                        v.is_activated === 1 &&
                        v.name_type_gudang == "Gudang Stok"
                    ) {
                        this.dataGudang.push(v);
                    }
                });
            });
        },

        // getDate() {
        //   this.getExpiredDate();
        //   this.getManufacturedDate();
        // },
        async getGudangAsal() {
            const defaultSelect = { id: "", name: "semua gudang" };
            this.dataGudangAsal.push(defaultSelect);
            axios.get("v1/user/gudang/list?limit=100").then((response) => {
                response.data.data.forEach((v) => {
                    // this.dataGudangAsal.push(v);
                    if (
                        v.is_activated === 1 &&
                        v.name_type_gudang == "Gudang Retur"
                    ) {
                        this.dataGudangAsal.push(v);
                    }
                });
            });
        },
        async getGudangTujuan() {
            const defaultSelect = { id: "", name: "semua gudang" };
            this.dataGudangTujuan.push(defaultSelect);
            this.user.listGudang.forEach((v) => {
                axios.get("v1/user/gudang?id=" + v).then((response) => {
                    if (response.data.data.is_activated === 1) {
                        this.dataGudangTujuan.push(response.data.data);
                    }
                    this.applyFilter();
                });
            });
        },

        changeItemPerPage() {
            this.applyFilter();
        },
        prevPage() {
            if (this.page === 1) {
                return;
            } else {
                this.page = this.page - 1;
                this.applyFilter();
            }
        },
        nextPage() {
            if (this.dataBarcode === null) {
                return;
            } else {
                this.page = this.page + 1;
                this.applyFilter();
            }
        },
        next(page) {
            this.page = page;
            this.applyFilter();
        },

        getTransType() {
            axios.get("v1/user/Scan/Out").then((response) => {
                response.data.data.forEach((element) => {
                    if (element.inisial === "ANTAR_GUDANG") {
                        this.olahData.moving_state_out_id = element.id;
                    }
                });
            });

            axios.get("v1/user/Scan/In").then((response) => {
                response.data.data.forEach((element) => {
                    if (element.inisial === "ANTAR_GUDANG") {
                        this.olahData.moving_state_id = element.id;
                    }
                });
            });
        },
        async edit(index) {
            this.olahData = this.dataInbound[index];
            // console.log(this.dataInbound[index]);
            // var tgl = this.dataInbound[index].tgl.split("-");
            // var date = tgl[2].split("T");
            // this.olahData.tgl = tgl[0] + "-" + tgl[1] + "-" + date[0];
            this.olahData.gudang_asal_id = this.olahData.gudang_penerima_id;
            this.olahData.nomer_seri_ref = this.olahData.nomer_seri;
            this.olahData.gudang_penerima_id = "";
            this.olahData.nomer_seri = "";
            this.getTransType();

            this.olahData.sjid = this.dataInbound[index].sj_id;
            this.editDataDialog = true;
        },
        reloadDataEdit() {
            this.popTitle = "Transaksi Antar Gudang berhasil diperbaharui";
            this.popDialog = true;
        },
        closeEditDataDialog() {
            this.dataInbound = [];
            this.applyFilter();
            this.editDataDialog = false;
        },

        closePopDialog() {
            this.popTitle = "";
            this.popDialog = false;
        },
    },
    created() {
        this.getGudangAsal();
        this.applyFilter();
        // this.getDate();
    },
};
</script>
