<template>
    <v-dialog v-model="cetakFormDialog" persistent max-width="80%">
        <v-card class="pa-5">
            <div id="printMe">
                <div style="border: 1px solid black">
                    <div style="margin-left: 15px; margin-top: 5px">
                        <b style="font-size: 11pt">TRANSAKSI OPNAME</b>
                    </div>

                    <div
                        style="margin-left: 15px; margin-right: 15px; margin-top: 20px"
                    >
                        <table
                            style="
                font-size: 11pt;
                width: 60%;
                float: left;
                margin-bottom: 30pt;
              "
                        >
                            <tr>
                                <td>Tanggal</td>
                                <td>:</td>
                                <td>{{ formatDate(olahData.tgl) }}</td>
                            </tr>
                            <tr>
                                <td>No. Opname</td>
                                <td>:</td>
                                <td>{{ olahData.nomer_seri }}</td>
                            </tr>
                            <tr>
                                <td>Gudang</td>
                                <td>:</td>
                                <td>{{ olahData.gudang_name }}</td>
                            </tr>
                        </table>
                    </div>

                    <div
                        style="margin-top: 20px; margin-left: 15px; margin-right: 15px"
                    >
                        <table style="font-size: 11pt" width="100%">
                            <thead>
                                <tr style="text-align: left">
                                    <th style="padding: 5px">Material</th>
                                    <th style="padding: 5px">Tipe Box</th>
                                    <th style="padding: 5px">Qty System</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: left">
                                    <td style="padding: 5px">
                                        {{ olahData.material_name }}
                                    </td>
                                    <td style="padding: 5px">
                                        {{ olahData.type_box_name }}
                                    </td>
                                    <td style="padding: 5px">
                                        {{
                                            getQtyCalculate(
                                                olahData.qty_gudang,
                                                olahData
                                            )
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div
                        style="
              margin-top: 30pt;
              margin-bottom: 14px;
              margin-left: 15px;
              margin-right: 15px;
            "
                    >
                        <table style="font-size: 11pt" width="100%">
                            <td align="left" style="padding: 5px">
                                Dibuat oleh,
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                ( {{ user }} )
                            </td>
                            <td align="left" style="padding: 5px">
                                Disetujui oleh,
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                (________________________)
                                <br />
                                Acc
                            </td>
                        </table>
                    </div>
                </div>
            </div>

            <v-card-actions class="px-0">
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    color="#61B15A"
                    class="text-subtitle-2"
                    style="text-transform: unset !important"
                    @click="closeCetakForm"
                >
                    Tutup
                </v-btn>
                <v-btn
                    color="#61B15A"
                    class="text-subtitle-2 white--text"
                    style="text-transform: unset !important"
                    @click="print"
                >
                    <v-icon left>mdi-printer</v-icon>
                    Cetak
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        cetakFormDialog: Boolean,
        olahData: Object,
        user: String,
    },

    methods: {
        async print() {
            await this.$htmlToPaper("printMe");
        },
        formatDate(value) {
            var monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            var newDate = new Date(value);
            return (
                newDate.getDate() +
                " " +
                monthNames[newDate.getMonth()] +
                " " +
                newDate.getFullYear()
            );
        },

        closeCetakForm() {
            this.$emit("closeCetakFormDialog");
        },
    },
};
</script>
