<template>
    <div>
        <v-container fluid class="px-0">
            <v-card outlined elevation="3">
                <v-card-title class="py-0 mx-4 mt-5 mb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="modify"
                        color="#61B15A"
                        class="text-subtitle-2 white--text"
                        style="text-transform: unset !important"
                        @click="addData"
                    >
                        <v-icon left>mdi-plus-circle</v-icon>
                        Tambah Stock Opname
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-data-table
                        class="black--text"
                        :headers="headers"
                        :search="search"
                        :items="dataTransaksiStockOpname"
                        :loading="loading"
                        :items-per-page="itemsPerPage"
                        :page.sync="page"
                        hide-default-footer
                        dense
                    >
                        <template v-slot:top>
                            <v-toolbar flat dense>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    autocomple="off"
                                    placeholder="Search..."
                                ></v-text-field>
                            </v-toolbar>
                        </template>

                        <template v-slot:item.tgl="{ item }">
                            {{ item.tgl | moment("DD/MM/YYYY") }}
                        </template>

                        <template v-slot:item.qty_gudang="{ item }">
                            {{ getQtyCalculate(item.qty_gudang, item) }}
                        </template>

                        <template v-slot:item.qty_scan="{ item }">
                            {{ getQtyCalculate(item.qty_scan, item) }}
                        </template>

                        <template v-slot:item.is_activated="{ item }">
                            <template v-if="item.is_verified === 1">
                                <v-chip
                                    small
                                    class="font-weight-bold primary--text"
                                >
                                    Sukses
                                </v-chip>
                            </template>
                            <template v-else>
                                <v-chip
                                    small
                                    class="font-weight-bold error--text"
                                    v-if="item.is_activated === 0"
                                >
                                    Disabled
                                </v-chip>
                                <v-chip
                                    small
                                    class="font-weight-bold primary--text"
                                    v-else
                                >
                                    Active
                                </v-chip>
                            </template>
                        </template>

                        <template v-slot:item.actions="{ item, index }">
                            <template v-if="modify">
                                <v-btn
                                    v-if="item.qty_scan === null"
                                    color="#FFA900"
                                    small
                                    class="text-caption white--text mr-2"
                                    style="text-transform: unset !important"
                                    @click.prevent="edit(index)"
                                >
                                    Edit {{ item.qty_scan }}
                                </v-btn>
                                <v-btn
                                    v-else
                                    color="#FFA900"
                                    small
                                    class="text-caption white--text mr-2"
                                    style="text-transform: unset !important"
                                    @click.prevent="edit(index)"
                                    disabled
                                >
                                    Edit
                                </v-btn>
                            </template>
                            <v-btn
                                color="#185ADB"
                                small
                                class="text-caption white--text mr-2"
                                style="text-transform: unset !important"
                                @click.prevent="cetakForm(index)"
                            >
                                Cetak Form
                            </v-btn>
                            <template>
                                <v-btn
                                    v-if="item.qty_scan != null"
                                    color="#185ADB"
                                    small
                                    class="text-caption white--text mr-2"
                                    style="text-transform: unset !important"
                                    @click.prevent="cetakBeritaAcara(index)"
                                >
                                    Cetak Berita Acara
                                </v-btn>
                                <v-btn
                                    v-else
                                    color="#185ADB"
                                    small
                                    class="text-caption white--text mr-2"
                                    style="text-transform: unset !important"
                                    @click.prevent="cetakBeritaAcara(index)"
                                    disabled
                                >
                                    Cetak Berita Acara
                                </v-btn>
                            </template>
                            <template v-if="modify">
                                <template>
                                    <v-btn
                                        v-if="
                                            item.qty_scan != null &&
                                                item.is_activated === 1 &&
                                                item.is_verified === 0
                                        "
                                        color="#185ADB"
                                        small
                                        class="text-caption white--text mr-2"
                                        style="text-transform: unset !important"
                                        @click.prevent="adjustment(item.id)"
                                    >
                                        Adjustment
                                    </v-btn>
                                    <v-btn
                                        v-else
                                        color="#185ADB"
                                        small
                                        class="text-caption white--text mr-2"
                                        style="text-transform: unset !important"
                                        @click.prevent="adjustment(item.id)"
                                        disabled
                                    >
                                        Adjustment
                                    </v-btn>
                                </template>
                                <template>
                                    <v-btn
                                        v-if="
                                            item.qty_scan === null ||
                                                item.is_activated === 0
                                        "
                                        color="#EB4646"
                                        small
                                        class="text-caption white--text mr-2"
                                        style="text-transform: unset !important"
                                        @click.prevent="hapus(index)"
                                    >
                                        <span v-if="item.is_activated === 0"
                                            >Active</span
                                        >
                                        <span v-else>Disabled</span>
                                    </v-btn>
                                    <v-btn
                                        v-else
                                        color="#EB4646"
                                        small
                                        class="text-caption white--text mr-2"
                                        style="text-transform: unset !important"
                                        @click.prevent="hapus(index)"
                                        disabled
                                    >
                                        <span v-if="item.is_activated === 0"
                                            >Active</span
                                        >
                                        <span v-else>Disabled</span>
                                    </v-btn>
                                </template>
                            </template>
                        </template>
                    </v-data-table>
                    <!-- Bottom Pagination -->
                    <div
                        class="d-flex mt-5 px-4 justify-space-between align-center"
                    >
                        <v-row>
                            <v-col cols="2">
                                <v-select
                                    v-model="itemsPerPage"
                                    :items="showPerPage"
                                    outlined
                                    dense
                                    label="items per page"
                                    v-on:change="changeItemPerPage"
                                >
                                </v-select>
                            </v-col>
                            <v-col align="right">
                                <span class="mr-15 text-caption"
                                    >Page: {{ page }}</span
                                >
                                <v-btn
                                    fab
                                    x-small
                                    depressed
                                    color="primary"
                                    class="mx-3"
                                    @click="prevPage"
                                >
                                    <v-icon dark> mdi-chevron-left </v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    x-small
                                    depressed
                                    color="primary"
                                    class="mx-3"
                                    @click="nextPage"
                                >
                                    <v-icon dark> mdi-chevron-right </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>

        <olah-data
            :olahDataDialog="olahDataDialog"
            :typeAction="typeAction"
            :olahData="olahData"
            :listMaterial="listMaterial"
            :dataGudang="dataGudang"
            :tipeBox="tipeBox"
            :olahDataTitle="olahDataTitle"
            :olahDataBtn="olahDataBtn"
            @closeOlahDataDialog="closeOlahDataDialog"
            @reloadOlahData="reloadOlahData"
        />

        <cetak-form
            :cetakFormDialog="cetakFormDialog"
            :olahData="olahData"
            :user="user.dataUser.name"
            @closeCetakFormDialog="closeCetakFormDialog"
        />

        <cetak-berita-acara
            :cetakBeritaAcaraDialog="cetakBeritaAcaraDialog"
            :olahData="olahData"
            :user="user.dataUser.name"
            @closeCetakBeritaAcaraDialog="closeCetakBeritaAcaraDialog"
        />

        <v-dialog persistent v-model="popDialog" width="500">
            <v-card color="white" rounded>
                <v-card-title class="justify-center text-h6 black--text">
                    {{ popTitle }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light-green darken-1"
                        class="white--text"
                        @click="closePopDialog"
                        >Tutup</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" persistent max-width="500px">
            <v-card>
                <v-card-title class="text-h6 font-weight-bold black--text">
                    Ubah status Surat Jalan
                </v-card-title>
                <v-card-subtitle class="mt-1 text-subtitle-2 black--text">
                    Status Surat Jalan akan dirubah. Yakin melanjutkan proses
                    ini?
                </v-card-subtitle>
                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="#61B15A"
                        style="text-transform: unset !important"
                        @click="closeDelete"
                    >
                        Batal
                    </v-btn>
                    <v-btn
                        color="#EB4646"
                        class="white--text"
                        style="text-transform: unset !important"
                        @click="deleteItemConfirm"
                        :loading="loadingDelete"
                    >
                        Proses
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import olahData from "./olahData.vue";
import CetakForm from "../stockOpname/cetakForm.vue";
import CetakBeritaAcara from "./cetakBeritaAcara.vue";

export default {
    components: { olahData, CetakForm, CetakBeritaAcara },
    props: { modify: Boolean },
    data: () => ({
        search: "",
        loading: false,
        headers: [
            {
                text: "Date Created",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "tgl",
            },
            {
                text: "No. Stock Opname",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "nomer_seri",
            },
            {
                text: "Material",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "material_name",
            },
            {
                text: "Quantity",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "qty_gudang",
            },
            {
                text: "Quantity Scan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "qty_scan",
            },
            {
                text: "Status",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "is_activated",
                width: "150px",
            },
            {
                text: "Action",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "actions",
            },
        ],
        dataTransaksiStockOpname: [],
        listMaterial: [],
        dataGudang: [],
        listGudang: [],
        tipeBox: [],
        page: 1,
        itemsPerPage: 10,
        showPerPage: [5, 10, 20, 50, 100, 250, 500],
        olahDataDialog: false,
        olahData: {
            nomer_seri: "",
            gudang_id: "",
            type_box_id: "",
            material_id: "",
            tgl: "",
        },
        olahDataTitle: "",
        olahDataBtn: "",
        typeAction: "",
        cetakFormDialog: false,
        cetakBeritaAcaraDialog: false,
        popDialog: false,
        popTitle: "",
        dialogDelete: false,
        loadingDelete: false,
        deleteData: {},
    }),

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),

        getData() {
            this.loading = true;
            axios
                .get(
                    "v1/admin/surat_op/list?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page
                )
                .then((response) => {
                    this.dataTransaksiStockOpname = response.data.data;
                    this.getMaterial();
                    this.getGudang();
                    this.getTipeBox();
                    this.loading = false;
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.getData();
                        }
                    }
                });
        },
        getMaterial() {
            axios
                .get("v1/admin/material/list?limit=100&page=1")
                .then((response) => {
                    this.listMaterial = response.data.data;
                });
        },
        getGudang() {
            this.listGudang = [];
            this.user.listGudang.forEach((v) => {
                axios.get("v1/user/gudang?id=" + v).then((response) => {
                    if (response.data.data.is_activated === 1) {
                        this.listGudang.push(response.data.data);
                    }
                });
            });
            axios.get("v1/user/gudang/list?limit=100").then((response) => {
                response.data.data.forEach((v) => {
                    if (v.is_activated === 1) {
                        this.dataGudang.push(v);
                    }
                });
            });
        },
        getTipeBox() {
            axios
                .get("v1/admin/box_type/list?limit=10&page=1")
                .then((response) => {
                    this.tipeBox = response.data.data;
                });
        },

        changeItemPerPage() {
            this.dataTransaksiStockOpname = [];
            this.getData();
        },
        prevPage() {
            if (this.page === 1) {
                return;
            } else {
                this.page = this.page - 1;
                this.dataTransaksiStockOpname = [];
                this.getData();
            }
        },
        nextPage() {
            if (this.dataTransaksiStockOpname === null) {
                return;
            } else {
                this.page = this.page + 1;
                this.dataTransaksiStockOpname = [];
                this.getData();
            }
        },
        addData() {
            this.typeAction = "new";
            this.olahDataTitle = "Tambah Stock Opname";
            this.olahDataBtn = "Simpan";
            this.olahDataDialog = true;
        },
        edit(index) {
            this.olahData = this.dataTransaksiStockOpname[index];
            this.olahData.tgl = this.olahData.tgl.split(" ")[0];
            this.typeAction = "edit";
            this.olahDataTitle = "Edit Stock Opname";
            this.olahDataBtn = "Update";
            this.olahDataDialog = true;
        },
        cetakForm(index) {
            this.olahData = this.dataTransaksiStockOpname[index];
            this.cetakFormDialog = true;
        },
        cetakBeritaAcara(index) {
            this.olahData = this.dataTransaksiStockOpname[index];
            this.cetakBeritaAcaraDialog = true;
        },
        adjustment(value) {
            const data = {};
            data.id = value;

            this.loading = true;
            const dataSend = JSON.stringify(data);
            axios
                .put("v1/admin/surat_op/adjust", dataSend, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status === 201) {
                        this.dataTransaksiStockOpname = [];
                        this.getData();
                    }
                });
        },
        hapus(id) {
            this.deleteData = this.dataTransaksiStockOpname[id];
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.loadingDelete = true;
            const data = this.deleteData;
            if (this.deleteData.is_activated === 0) {
                data.is_activated = 1;
            } else {
                data.is_activated = 0;
            }
            const dataSend = JSON.stringify(data);
            axios
                .put("v1/admin/surat_op", dataSend, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status === 201) {
                        this.loadingDelete = false;
                        this.closeDelete();
                    }
                });
        },
        closeDelete() {
            this.dataTransaksiStockOpname = [];
            this.deleteData = {};
            this.getData();
            this.dialogDelete = false;
        },
        reloadOlahData() {
            if (this.typeAction === "new") {
                this.popTitle = "Surat Stock Opname Berhasil Ditambahkan";
                this.popDialog = true;
            } else {
                this.popTitle = "Surat Stock Opname Berhasil Diperbaharui";
                this.popDialog = true;
            }
        },
        closeOlahDataDialog() {
            this.olahData = {};
            this.olahDataDialog = false;
            this.getData();
        },
        closeCetakFormDialog() {
            this.dataTransaksiStockOpname = [];
            this.getData();
            this.olahData = {};
            this.cetakFormDialog = false;
        },
        closeCetakBeritaAcaraDialog() {
            this.dataTransaksiStockOpname = [];
            this.getData();
            this.olahData = {};
            this.cetakBeritaAcaraDialog = false;
        },
        closePopDialog() {
            this.typeAction = "";
            this.popTitle = "";
            this.popDialog = false;
        },
    },

    mounted() {
        this.getData();
    },
};
</script>
