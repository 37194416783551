<template>
    <div>
        <v-dialog v-model="editDataDialog" persistent max-width="80%">
            <v-card>
                <v-card-title class="text-h5 font-weight-bold">
                    TP Gd.Retur ke Gd. Stock
                </v-card-title>
                <v-divider class="mx-5"></v-divider>

                <v-card-text class="mt-3">
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col>
                                <!-- Nomor Surat Jalan -->
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Nomor Surat Jalan
                                </v-card-title>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="olahData.nomer_seri"
                                    :rules="[rules.required]"
                                    readonly
                                    filled
                                ></v-text-field>

                                <!-- Asal Gudang -->
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Gudang Asal
                                </v-card-title>
                                <v-select
                                    placeholder="Gudang Asal"
                                    dense
                                    outlined
                                    v-model="olahData.gudang_asal_id"
                                    :items="listGudang"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    readonly
                                    filled
                                ></v-select>
                                <!-- Tujuan Gudang -->
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Gudang Penerima
                                </v-card-title>
                                <v-select
                                    placeholder="Gudang Penerima"
                                    dense
                                    outlined
                                    v-model="olahData.gudang_penerima_id"
                                    :items="dataGudang"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.required]"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <!-- Nomor Surat Jalan Ref -->
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Nomor Surat Jalan Ref
                                </v-card-title>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="olahData.nomer_seri_ref"
                                    readonly
                                    filled
                                ></v-text-field>
                                <v-card-title
                                    class="pa-0 text-subtitle-1 font-weight-bold black--text"
                                >
                                    Dibuat Oleh
                                </v-card-title>
                                <v-select
                                    v-model="olahData.dibuat_id"
                                    :items="listNama"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.requireddibuat]"
                                    dense
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    placeholder="Search"
                                    dense
                                    outlined
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-data-table
                                    v-model="selectMaterial"
                                    item-key="id"
                                    show-select
                                    :search="search"
                                    :headers="headersMaterial"
                                    :items="indexedItems"
                                    :items-per-page="itemsPerPage"
                                    :loading="loadingTable"
                                    :page.sync="page"
                                    hide-default-footer
                                    dense
                                >
                                    <template v-slot:top>
                                        <v-row>
                                            <v-col cols="2">
                                                <v-select
                                                    v-model="itemsPerPage"
                                                    :items="showPerPage"
                                                    outlined
                                                    dense
                                                    label="items per page"
                                                    v-on:change="
                                                        changeItemPerPage
                                                    "
                                                >
                                                </v-select>
                                            </v-col>
                                            <v-col align="right">
                                                <span class="mr-15 text-caption"
                                                    >Page: {{ page }}</span
                                                >
                                                <v-btn
                                                    fab
                                                    x-small
                                                    depressed
                                                    color="primary"
                                                    class="mx-3"
                                                    @click="prevPage"
                                                >
                                                    <v-icon dark>
                                                        mdi-chevron-left
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    fab
                                                    x-small
                                                    depressed
                                                    color="primary"
                                                    class="mx-3"
                                                    @click="nextPage"
                                                >
                                                    <v-icon dark>
                                                        mdi-chevron-right
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-slot:item.total="{ item }">
                                        <span>
                                            {{ getQtyStock(item) }}
                                        </span>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions class="pb-5">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        color="#61B15A"
                        style="text-transform: unset !important"
                        @click="closeEditData"
                    >
                        Batal
                    </v-btn>
                    <v-btn
                        color="#61B15A"
                        class="white--text"
                        style="text-transform: unset !important"
                        @click="checkType"
                        :disabled="!valid"
                        :loading="loading"
                    >
                        {{ loading ? "Mohon tunggu..." : "Proses" }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="popConfirm" max-width="450">
            <v-card>
                <v-card-title class="light-blue white--text">
                    Konfirmasi Proses TP
                </v-card-title>

                <v-card-text class="pt-4 black--text">
                    Anda yakin akan melakukan Proses TP data yang dipilih ?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="light-green lighten-2" dark @click="goExec">
                        Ya
                    </v-btn>

                    <v-btn color="red lighten-2" dark @click="goBack">
                        Tidak
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="popDialog" width="500">
            <v-card color="white" rounded>
                <v-card-title class="justify-center text-h6 black--text">
                    {{ popTitle }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="light-green darken-1"
                        class="white--text"
                        @click="closePopDialog"
                        >Tutup</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
    props: {
        editDataDialog: Boolean,
        olahData: Object,
        dataGudang: Array,
        listGudang: Array,
    },

    data: () => ({
        maxnote: 40,
        valid: false,
        loading: false,
        loadingTable: false,
        page: 1,
        itemsPerPage: 100,
        showPerPage: [5, 10, 20, 50, 100, 250, 500],
        dateMenu: false,
        search: "",
        popConfirm: false,
        rules: {
            required: (value) => !!value || "Tidak boleh kosong !!",
            requireddibuat: (value) =>
                !!value || "Data signature gudang asal tidak ditemukan",
            maxQty(value, stock, callbackDivider) {
                var max = stock;
                const divider = callbackDivider();
                max = stock / divider;
                return Number(value || "") <= Number(max) || `Maximal ${max}`;
            },
        },
        selectMaterial: [],
        list_summary: [],
        listNama: [],
        headersMaterial: [
            {
                text: "Material",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "material",
            },
            {
                text: "Batch",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "batch_no",
            },
            {
                text: "MC Code/IC Code/QR Code",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "kode_scan",
            },
            {
                text: "Satuan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "satuan",
            },
            {
                text: "Quantity Stock",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "total",
            },
        ],
        dataMaterial: [],
        listData: [],
        check: 0,
        popDialog: false,
        popTitle: "",
        key: 0,
    }),

    watch: {
        editDataDialog(visible) {
            console.log(this.olahData);
            if (visible) {
                this.changeGudangAsal();
                this.getMaterial();
            }
        },
    },

    computed: {
        indexedItems() {
            return this.dataMaterial.map((item, index) => ({
                id: index,
                ...item,
            }));
        },
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),

        checkType() {
            if (this.selectMaterial.length === 0) {
                this.popTitle = "Pilih Material !!";
                this.popDialog = true;
            } else {
                this.selectMaterial.forEach((element) => {
                    if (element.total === "") {
                        this.check = 1;
                    }
                });
                if (this.check === 0) {
                    this.save();
                } else {
                    this.popTitle =
                        "Jumlah Quantity Kirim Tidak Boleh Kosong !!!";
                    this.check = 0;
                    this.popDialog = true;
                }
            }
        },
        goExec() {
            this.popConfirm = false;
            this.prosesSave();
        },
        goBack() {
            this.popConfirm = false;
        },

        save() {
            // this.prosesSave();
            this.popConfirm = true;
        },

        async prosesSave() {
            var deviceId = "";
            this.loading = true;
            var sukses = "Z";
            var genid = "";
            // this.olahData.no_kendaraan = this.olahData.no_kendaraan.toUpperCase();
            // this.olahData.no_do_sales = this.olahData.no_do_sales.toUpperCase();
            // this.olahData.moving_state_id = this.moving_state_id;
            this.olahData.tgl = new Date(Date.now());

            // console.log(this.olahData);

            if (
                this.olahData.gudang_asal_id ===
                this.olahData.gudang_penerima_id
            ) {
                this.loading = false;
                this.popTitle =
                    "Gudang Asal dan Gudang Penerima Tidak Boleh Sama !!!";
                this.check = 0;
                this.popDialog = true;
            } else {
                var list = {};
                var flag = "";
                this.selectMaterial.forEach((v) => {
                    list.batch_no = v.batch_no;
                    list.type_box_id = v.type_box_id;
                    list.total = v.total;
                    list.total_diterima = "0";
                    flag = "";
                    this.list_summary.filter(function(data) {
                        if (
                            data.batch_no === v.batch_no &&
                            data.type_box_id === v.type_box_id
                        ) {
                            data.total = data.total + v.total;
                            flag = "X";
                        }
                    });
                    if (flag == "") {
                        this.list_summary.push(list);
                    }
                    list = {};
                });

                await axios
                    .get("v1/admin/device/kode?kode=jangandihapustp1")
                    .then((response) => {
                        deviceId = response.data.data.id;
                    });

                const data = JSON.stringify(this.olahData);
                console.log(data);
                await axios
                    .post("v1/admin/surat_jalan/TPRetur", data, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((r) => {
                        const summ = {};
                        sukses = "X";
                        summ.id = r.data.data.genUID;
                        genid = r.data.data.genUID;
                        summ.list_summary = this.list_summary;
                        const summSend = JSON.stringify(summ);
                        axios
                            .put("v1/admin/surat_jalan/summ", summSend, {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            })
                            .then(() => {
                                console.log(sukses);
                            });
                    });

                if (sukses == "X") {
                    this.selectMaterial.forEach((v) => {
                        this.listData.push(v.kode_scan);
                    });
                    const dataOut = {};
                    dataOut.list_data = this.listData;
                    dataOut.moving_state_id = this.olahData.moving_state_out_id;
                    dataOut.surat_jalan_id = genid;
                    dataOut.gudang_id = this.olahData.gudang_asal_id;
                    dataOut.device_id = deviceId;
                    dataOut.customer_id = "";
                    const dataOutSend = JSON.stringify(dataOut);
                    await axios
                        .post("v1/user/scan/tp_retur/out", dataOutSend, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        })
                        .then(async () => {
                            const dataIn = {};
                            dataIn.list_data = this.listData;
                            dataIn.moving_state_id = this.olahData.moving_state_id;
                            dataIn.surat_jalan_id = genid;
                            dataIn.gudang_id = this.olahData.gudang_penerima_id;
                            dataIn.device_id = deviceId;
                            dataIn.customer_id = "";
                            const dataInSend = JSON.stringify(dataIn);
                            await axios
                                .post("v1/user/scan/tp_retur/in", dataInSend, {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                })
                                .then(() => {
                                    setTimeout(() => {
                                        this.closeEditData();
                                    }, 10000);
                                });
                        });
                }

                //  setTimeout(() => {
                //   if(sukses=="Y"){
                //     const dataIn = {};
                //       dataIn.list_data = this.listData;
                //       dataIn.moving_state_id = this.olahData.moving_state_id;
                //       dataIn.surat_jalan_id = genid;
                //       dataIn.gudang_id = this.olahData.gudang_penerima_id;
                //       dataIn.device_id = deviceId;
                //       dataIn.customer_id = "";
                //       const dataInSend = JSON.stringify(dataIn);
                //       axios
                //       .post("v1/user/scan/tp_retur/in", dataInSend, {
                //         headers: {
                //           "Content-Type": "application/json",
                //         },
                //       })
                //       .then(() => {
                //         this.closeEditData();

                //         });

                //  }
                //  console.log("World!"); }, 10000);
            }
        },

        getMaterial() {
            this.dataMaterial = [];
            this.loadingTable = true;
            axios
                .get(
                    "/v1/admin/surat_jalan/tp_retur/list?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page +
                        "&sj_id=" +
                        this.olahData.sjid
                )
                .then((response) => {
                    this.dataMaterial = response.data.data;
                    this.loadingTable = false;
                    this.getNo();
                });
            // this.dataMaterial = this.olahData.selectMaterial;
            // console.log(this.olahData);
        },
        async changeGudangAsal() {
            this.listNama = [];
            await axios
                .get(
                    "v1/admin/signature/list?gudang_id=" +
                        this.olahData.gudang_asal_id
                )
                .then((response) => {
                    this.olahData.dibuat_id = null;
                    response.data.data.forEach((v) => {
                        if (v.is_activated === 1) {
                            this.listNama.push(v);
                        }
                    });
                    this.populateDataNama(response.data.data);
                });
        },
        async populateDataNama(value) {
            value.forEach((element) => {
                if (element.is_activated === 1) {
                    axios
                        .get(
                            "v1/admin/signature_type?id=" +
                                element.signature_type_id
                        )
                        .then((response) => {
                            if (response.data.data.kode === "DIBUAT_OLEH") {
                                this.olahData.dibuat_id = element.id;
                            }
                        });
                }
            });
        },
        async getNo() {
            await axios
                .get(
                    "v1/admin/surat/seri?type=SJT&gudang_id=" +
                        this.olahData.gudang_asal_id
                )
                .then((response) => {
                    this.olahData.nomer_seri = response.data.data;
                });
        },
        changeItemPerPage() {
            if (this.dataMaterial.length === 0) {
                return;
            } else {
                this.getMaterial();
            }
        },
        prevPage() {
            if (this.page === 1) {
                return;
            } else {
                this.page = this.page - 1;
                this.getMaterial();
            }
        },
        nextPage() {
            if (this.dataMaterial.length === 0) {
                return;
            } else {
                this.page = this.page + 1;
                this.getMaterial();
            }
        },

        closeEditData() {
            this.loading = false;
            this.resetData();
            this.$refs.form.resetValidation();
            this.$emit("closeEditDataDialog");
        },
        resetData() {
            this.selectMaterial = [];
            this.dataMaterial = [];
            this.list_summary = [];
            this.check = 0;
            this.olahData.nomer_seri = "";
            this.olahData.sjid = "";
            this.olahData.gudang_asal_id = "";
            this.olahData.gudang_penerima_id = "";
            this.olahData.tgl = null;
            this.olahData.dibuat_id = "";
            this.listData = [];
        },
        closePopDialog() {
            this.popTitle = "";
            this.popDialog = false;
        },
    },
};
</script>
