<template>
  <div>
    <v-dialog v-model="olahDataDialog" persistent max-width="80%">
      <v-card>
        <v-card-title class="text-h5 font-weight-bold">
          {{ olahDataTitle }}
        </v-card-title>
        <v-divider class="mx-5"></v-divider>

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col>
                <v-card-title
                  class="pa-0 text-subtitle-1 font-weight-bold black--text"
                >
                  Nomor Stock Opname
                </v-card-title>
                <v-text-field
                  outlined
                  dense
                  filled
                  readonly
                  v-model="olahData.nomer_seri"
                ></v-text-field>

                <v-card-title
                  class="pa-0 text-subtitle-1 font-weight-bold black--text"
                >
                  Tanggal
                </v-card-title>
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-left="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="olahData.tgl"
                      append-icon="mdi-calendar-month"
                      placeholder="Pilih tanggal"
                      readonly
                      dense
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      :rules="tglRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="olahData.tgl"
                    @input="dateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>

                <v-card-title
                  class="pa-0 text-subtitle-1 font-weight-bold black--text"
                >
                  Material
                </v-card-title>
                <v-select
                  placeholder="Pilih Material"
                  dense
                  outlined
                  v-model="olahData.material_id"
                  :items="listMaterial"
                  item-value="id"
                  item-text="desc"
                  :rules="materialRules"
                ></v-select>
              </v-col>
              <v-col>
                <v-card-title
                  class="pa-0 text-subtitle-1 font-weight-bold black--text"
                >
                  Gudang
                </v-card-title>
                <v-select
                  placeholder="Gudang"
                  dense
                  outlined
                  v-model="olahData.gudang_id"
                  :items="dataGudang"
                  item-value="id"
                  item-text="name"
                  :rules="gudangRules"
                  v-on:change="changeGudang"
                ></v-select>

                <v-card-title
                  class="pa-0 text-subtitle-1 font-weight-bold black--text"
                >
                  Tipe Box
                </v-card-title>
                <v-select
                  placeholder="Tipe Box"
                  dense
                  outlined
                  v-model="olahData.type_box_id"
                  :items="tipeBox"
                  item-value="id"
                  item-text="name"
                  :rules="tipeBoxRules"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#61B15A"
            style="text-transform: unset !important"
            @click="closeOlahData"
          >
            Batal
          </v-btn>
          <v-btn
            color="#61B15A"
            class="white--text"
            style="text-transform: unset !important"
            @click="checkType"
            :disabled="!valid"
            :loading="loading"
          >
            {{ olahDataBtn }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="alert" width="500">
      <v-card color="red" rounded>
        <v-card-title class="justify-center text-h6 white--text">
          Simpan Data Error
        </v-card-title>
        <v-card-title class="justify-center text-subtitle-2 white--text">
          {{ alertTitle }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-green darken-1"
            class="white--text"
            @click="closeAlert"
            >Tutup</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    olahDataDialog: Boolean,
    olahData: Object,
    typeAction: String,
    olahDataTitle: String,
    olahDataBtn: String,
    listMaterial: Array,
    dataGudang: Array,
    tipeBox: Array,
  },

  data: () => ({
    loading: false,
    valid: false,
    dateMenu: false,
    tglRules: [(v) => !!v || "Tanggal tidak boleh kosong"],
    materialRules: [(v) => !!v || "Material tidak boleh kosong"],
    gudangRules: [(v) => !!v || "Gudang tidak boleh kosong"],
    tipeBoxRules: [(v) => !!v || "Tipe Box tidak boleh kosong"],
    alert: false,
    alertTitle: "",
  }),

  methods: {
    checkType() {
      if (this.typeAction === "new") {
        this.simpan();
      } else if (this.typeAction === "edit") {
        this.update();
      }
    },
    simpan() {
      this.loading = true;
      const data = JSON.stringify(this.olahData);
      axios
        .post("v1/admin/surat_op", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.$emit("reloadOlahData");
            this.closeOlahData();
          } else {
            this.loading = false;
            this.alertTitle = response.data.errMessage;
            this.alert = true;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.loading = false;
            this.alertTitle = error.response.data.errMessage;
            this.alert = true;
          }
          this.loading = false;
        });
    },
    update() {
      const data = {};
      data.id = this.olahData.id;
      data.nomer_seri = this.olahData.nomer_seri;
      data.gudang_id = this.olahData.gudang_id;
      data.type_box_id = this.olahData.type_box_id;
      data.material_id = this.olahData.material_id;
      data.tgl = this.olahData.tgl;
      data.is_activated = this.olahData.is_activated;
      
      this.loading = true;
      const dataSend = JSON.stringify(data);
      axios
        .put("v1/admin/surat_op", dataSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            this.loading = false;
            this.$emit("reloadOlahData");
            this.closeOlahData();
          } else {
            this.loading = false;
            this.alertTitle = response.data.errMessage;
            this.alert = true;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.loading = false;
            this.alertTitle = error.response.data.errMessage;
            this.alert = true;
          }
          this.loading = false;
        });
    },
    async changeGudang() {
      await axios
        .get(
          "v1/admin/surat_op/seri?gudang_id=" +
            this.olahData.gudang_id +
            "&type=OP"
        )
        .then((response) => {
          this.olahData.nomer_seri = response.data.data;
        });
    },
    closeAlert() {
      this.alertTitle = "";
      this.alert = false;
    },
    closeOlahData() {
      this.$refs.form.reset();
      this.$emit("closeOlahDataDialog");
    },
  },
};
</script>